import React from "react"

import { useSanity } from "../../hooks/useSanity"

export const withHomepage = Component => ({ name = `Homepage`, page }) => {
  const { heroNormaliser } = useSanity()

  const content = {
    ...page,
    sections: [heroNormaliser(page?.heroBanner), ...page.content],
  }

  Component.displayName = name
  return <Component content={content} />
}
