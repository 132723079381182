import React from "react"

import { withHomepage } from "./withHomepage"
import { Sections } from "../Sections/Sections"

interface Props {
  content: any
}

export const Homepage = withHomepage(({ content }: Props) => <Sections content={content.sections} />)
